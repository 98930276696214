/*FONTS*/
@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https://use.typekit.net/eaq4wfz.css");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*GENERAL*/


body {
  margin: 0 0 24px 0;
  font-family: 'Work Sans', sans-serif;
  background-color: #FFFCF2;
}


.container {
  text-align: center;
  background-color: #A7D1E7; /* Light blue */
  color: #333;
  padding: 20px;
}

.hero {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  align-items: center; /* Vertically align items */
  justify-content: center; /* Center items horizontally */
  padding: 10px 340px 20px 340px;
  gap: 48px; /* Add space between the elements */
  text-align: left; /* Align text to the left */
}

/* .case-study-content {
    padding: 24px 340px 40px 340px;
} */

/* img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 24px;
  } */

.profile-pic img {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.intro {
  margin-top: 0px;
}

.intro h1 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-family: "FatFrank", "Work Sans", sans-serif; 
font-weight: 400;
font-style: normal;
}

.overline {
  font-family: "mrs-eaves-roman-small-caps", serif;
  font-weight: 500;
  font-style: normal;
}

.highlight {
  font-weight: bold;
  padding: 0 5px;
  text-decoration: underline;
	text-decoration-color: #FFF25C;
	text-decoration-thickness: 8px;
	text-decoration-skip-ink: none;
}

.icons {
  padding-top: 8px;
}

.icons a {
  font-size: 1.5rem;
  margin: 0 10px;
  text-decoration: none;
  color: #000;
}

.icons a:hover {
  color: #093548;
}

.about {
  background-color: #fdfdf5; /* Light cream */
  padding: 20px 340px 0px 340px;
}

.about h3 {
  font-size: 1.4rem;
}

.about p {
  margin: 10px 0;
}

.about a {
  color: #14506A;
  text-decoration: none;
}

.about a:hover {
  text-decoration: underline;
}

/* Mobile styles */
@media (max-width: 1043px) {
  .hero {
    flex-direction: column; /* Stack elements vertically */
    gap: 0px;
    text-align: center; /* Center text alignment */
    padding: 20px 0;
  }

    /* .case-study-content {
        padding: 20px;
    } */

  .profile-pic img {
    width: 200px; /* Adjust size for smaller screens */
    height: 200px;
  }

  .intro {
    margin-top: 10px; /* Reduce margin for compact layout */
  }

  .intro h1 {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
    line-height: 1.6rem;
  }

  .icons {
    padding-top: 10px;
  }

  .icons a {
    font-size: 1.2rem; /* Adjust icon size */
    margin: 5px; /* Reduce spacing */
  }

  .about {
    padding: 10px 20px;
  }


}